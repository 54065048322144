$(function(){

    if($('.equal-height').length)
    {
        $('.equal-height').matchHeight();
    }
    
    $('.sponsored-articles-top-sliders').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        mobileFirst: true,
        speed: 200,
        autoplay: true,
        autoplaySpeed: 3000,
        // vertical: true,
    });

    $('.sponsored-articles-top-sliders-desktop').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        mobileFirst: true,
        speed: 200,
        autoplay: true,
        autoplaySpeed: 3000,
        prevArrow: '<a href="javascript:void(0);" class="slick-prev"><i class="fa fa-chevron-left"></i></a>',
        nextArrow: '<a href="javascript:void(0);" class="slick-next"><i class="fa fa-chevron-right"></i></a>',
    });

});